import "dayjs/locale/en-gb";
import "dayjs/locale/en";
import "dayjs/locale/ar";
import "dayjs/locale/bg";
import "dayjs/locale/cs";
import "dayjs/locale/da";
import "dayjs/locale/de";
import "dayjs/locale/el";
import "dayjs/locale/es";
import "dayjs/locale/fi";
import "dayjs/locale/fr";
import "dayjs/locale/hi";
import "dayjs/locale/hr";
import "dayjs/locale/hu";
import "dayjs/locale/it";
import "dayjs/locale/ja";
import "dayjs/locale/ko";
import "dayjs/locale/lt";
import "dayjs/locale/ms";
import "dayjs/locale/nb";
import "dayjs/locale/nl";
import "dayjs/locale/pl";
import "dayjs/locale/pt-br";
import "dayjs/locale/pt";
import "dayjs/locale/ro";
import "dayjs/locale/ru";
import "dayjs/locale/sk";
import "dayjs/locale/sl";
import "dayjs/locale/sv";
import "dayjs/locale/ta";
import "dayjs/locale/th";
import "dayjs/locale/uk";
import "dayjs/locale/vi";
import "dayjs/locale/zh";

import dayjs, { extend, type Dayjs } from "dayjs";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";

import { DEFAULT_LANGUAGE } from "~/translations/constants";

extend(relativeTime);
extend(isBetween);
extend(isSameOrAfter);
extend(duration);
extend(localizedFormat);

dayjs.locale(DEFAULT_LANGUAGE);

export { dayjs, type Dayjs };
